import { __rest } from "tslib";
import { Menu } from '@headlessui/react';
import React from 'react';
import { HeadlessButton } from '../../button';
const defaultClass = 'block text-center no-underline border-none px-4 py-1.5 focus:bg-background-secondary focus:text-foreground-secondary hover:text-foreground-secondary hover:bg-background-secondary w-full';
export const MenuItem = (_a) => {
    var { children, className } = _a, props = __rest(_a, ["children", "className"]);
    return (React.createElement(Menu.Item, null, ({ active }) => {
        const activeStyles = active
            ? 'text-foreground-secondary bg-background-secondary outline outline-3 outline-blue-500 outline-offset-[-3px]'
            : 'text-background-secondary bg-foreground-secondary';
        const classes = [defaultClass, className, activeStyles].join(' ');
        return (React.createElement(HeadlessButton, Object.assign({ className: classes }, props), children));
    }));
};
