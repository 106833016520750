import { __rest } from "tslib";
import React, { createContext, useContext, useRef } from 'react';
import { Menu } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
const DropDownContext = createContext({
    menuButtonRef: React.createRef()
});
const dropDownItems = 'list-none bg-foreground-secondary text-center border-1 border-solid border-background-quaternary focus:outline-transparent origin-top-right absolute w-full min-w-max py-1 px-0 z-10';
const dropUpItems = dropDownItems + ' transform -translate-y-full top-0';
const toggleClassNames = 'cursor-pointer border-3 border-solid w-full block text-center touch-manipulation bg-background-quaternary text-foreground-secondary px-3 py-1.5 relative hover:bg-foreground-secondary hover:text-background-secondary btn-block border-foreground-secondary';
export const MenuItems = React.forwardRef(({ children, className }, ref) => {
    const { dropup, menuButtonRef } = useContext(DropDownContext);
    const handleClick = () => {
        var _a;
        (_a = menuButtonRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const itemsClasses = dropup ? dropUpItems : dropDownItems;
    const buttonClass = [className, itemsClasses].join(' ');
    return (React.createElement(Menu.Items, { className: buttonClass, ref: ref, onClick: handleClick }, children));
});
const DropDownButton = (_a) => {
    var { children, className } = _a, rest = __rest(_a, ["children", "className"]);
    const { dropup, menuButtonRef } = useContext(DropDownContext);
    const classes = [className, toggleClassNames].join(' ');
    return (React.createElement(Menu.Button, Object.assign({ ref: menuButtonRef, className: classes }, rest),
        children,
        React.createElement(FontAwesomeIcon, { icon: dropup ? faCaretUp : faCaretDown, className: 'mx-2 h-3', "aria-hidden": 'true' })));
};
export const Dropdown = (_a) => {
    var { children, dropup } = _a, props = __rest(_a, ["children", "dropup"]);
    const menuButtonRef = useRef(null);
    const context = { dropup, menuButtonRef };
    return (React.createElement(DropDownContext.Provider, { value: context },
        React.createElement(Menu, Object.assign({ className: 'relative w-full', as: 'div' }, props), children)));
};
Dropdown.Menu = MenuItems;
Dropdown.Toggle = DropDownButton;
MenuItems.displayName = 'MenuItems';
Dropdown.displayName = 'Dropdown';
