module.exports = [{
      plugin: require('../../node_modules/.pnpm/@sentry+gatsby@6.19.7_gatsby@3.15.0_react@16.14.0/node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":null},
    },{
      plugin: require('../../node_modules/.pnpm/gatsby-plugin-manifest@3.15.0_gatsby@3.15.0_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"freeCodeCamp","short_name":"fCC","start_url":"/","theme_color":"#0a0a23","background_color":"#fff","display":"minimal-ui","icon":"src/assets/images/square_puck.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6cba562cbd10e31af925a976f3db73f7"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
